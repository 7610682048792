import styles from './LocaleSelector.module.css';
import classNames from 'classnames/bind';
import { Fragment, useState } from 'react';
import { useRouter } from 'next/router';

const cx = classNames.bind(styles);

interface ILocale {
  [key: string]: {
    type: string;
    text: string;
  };
}

const locale: ILocale = Object.assign({
  ko: { type: 'ko', text: '한국어' },
  en: { type: 'en', text: 'English' },
});

interface ILocaleSelector {
  onClick?: () => void;
}

function LocaleSelector({ onClick }: ILocaleSelector) {
  const router = useRouter();
  const { pathname, asPath, locale: routerLocale, query } = router;
  const { id } = query;

  const [selectedLocale, setSelectedLocale] = useState(routerLocale);
  const handleChangeLocale = (key: string) => {
    setSelectedLocale(key);

    if (key === 'en') {
      const splitPath = pathname.split('/');

      // 블로그는 en 지원 안함
      if (splitPath[1] === 'blog') {
        return router.push('/', '/', { locale: 'en' });
      }

      // 공고(IR) en 지원 안함
      if (splitPath[1] === 'news' && splitPath[2] === 'announcement') {
        return router.push('/', '/', { locale: 'en' });
      }
    }

    // id 가 있으면 Detail page를 의미
    if (id) {
      const splitedPath = asPath.split('/');
      splitedPath.pop();
      const path = splitedPath.join('/');
      router.push(path, undefined, { locale: key });
    } else {
      router.push(pathname, undefined, { locale: key });
    }

    onClick && onClick();
  };

  return (
    <div className={cx('wrapper')}>
      {Object.keys(locale).map((key, index) => (
        <Fragment key={key}>
          <button
            className={cx({
              active: key === selectedLocale,
            })}
            onClick={() => handleChangeLocale(key)}
          >
            {locale[key].text}
          </button>
          {index !== Object.keys(locale).length - 1 && (
            <span className={cx('divider')}>|</span>
          )}
        </Fragment>
      ))}
    </div>
  );
}

export default LocaleSelector;
