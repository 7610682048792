
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import Image from 'next/image';
import { useRouter } from 'next/router';
import { Result } from 'antd';
import EmptyNinimo from 'public/images/empty-image-ninimo.jpg';
export default function NotFound() {
    const router = useRouter();
    const { locale } = router;
    // Opinionated: do not record an exception in Sentry for 404
    return (<Result icon={<Image src={EmptyNinimo} alt="empty image" width="300px" height="300px"/>} title="404" subTitle={locale === 'ko' ? '존재하지 않는 페이지입니다.' : 'Page not found.'}/>);
}

    async function __Next_Translate__getStaticProps__190ed1d403d__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__190ed1d403d__ as getStaticProps }
  