
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { useEffect } from 'react';
import BasicLayout from 'components/layouts/BasicLayout';
import type { AppProps } from 'next/app';
import '../styles/globals.css';
import Head from 'next/head';
import { ConfigProvider, FloatButton } from 'antd';
import axios from 'axios';
import { API_BASE_URL, FRONT_API_BASE_URL, RELEASE_ENV } from 'utils/config';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import NotFound from './404';
import GoogleTagManagerNoScript from 'components/GoogleTagManagerNoScript';
import GoogleTagManagerScript from 'components/GoogleTagManagerScript';
import GTagScript from 'components/GTagScript';
import favicon from 'public/favicon.ico';
axios.defaults.baseURL = API_BASE_URL;
function MyApp({ Component, pageProps }: AppProps) {
    const locale = pageProps.locale || 'en';
    const router = useRouter();
    const { locale: routerLocale, pathname, asPath } = router;
    const checkNotFound = (path: string) => {
        const splitPath = path.split('/');
        // 블로그는 en 지원 안함
        if (splitPath[1] === 'blog')
            return true;
        // 공고(IR) en 지원 안함
        if (splitPath[1] === 'news' && splitPath[2] === 'announcement')
            return true;
        return false;
    };
    useEffect(() => {
        dayjs.locale(locale);
    }, [locale]);
    // head 의 meta 태그는 동적할당 지원이 안되기 때문에 별도의 컴포넌트로 분리하지 않는다.
    const DefaultMetaTag = () => {
        return (<>
        <link rel="canonical" href={routerLocale === 'ko'
                ? `${FRONT_API_BASE_URL}${asPath}`
                : `${FRONT_API_BASE_URL}/en${asPath}`}/>
        <meta key="author" name="author" content={routerLocale === 'ko'
                ? '더핑크퐁컴퍼니 주식회사'
                : 'The Pinkfong Company'}/>
        <meta key="type" property="og:type" content="website"/>
      </>);
    };
    const OpengraphTag = () => {
        return (<>
        <meta key="og:site_name" property="og:site_name" content={routerLocale === 'ko'
                ? '더핑크퐁컴퍼니 주식회사'
                : 'The Pinkfong Company'}/>
        <meta key="og:title" property="og:title" content={routerLocale === 'ko'
                ? '더핑크퐁컴퍼니 주식회사'
                : 'The Pinkfong Company'}/>
        <meta key="og:url" property="og:url" content={routerLocale === 'ko'
                ? FRONT_API_BASE_URL
                : `${FRONT_API_BASE_URL}/en`}/>
        <meta key="og:image" property="og:image" content={`${FRONT_API_BASE_URL}/images/thumbnail.png`}/>
        <meta key="og:description" property="og:description" content="Connecting people around the world through joyful content"/>
      </>);
    };
    return (<ConfigProvider theme={{
            token: {
                colorPrimary: '#ff66af',
                colorLinkHover: '#ff66af',
                colorPrimaryHover: '#ff66af',
                colorPrimaryActive: '#ff66af',
            },
        }}>
      {/* google-tag-manager, gtag */}
      {RELEASE_ENV === 'production' && (<>
          <GoogleTagManagerScript />
          <GTagScript />
        </>)}
      <Head>
        <link rel="shortcut icon" href={favicon.src} type="image/x-icon"/>
        <link rel="icon" href={favicon.src} type="image/x-icon"/>
        <meta key="robots" name="robots" content={RELEASE_ENV === 'production' ? 'index,follow' : 'noindex,nofollow'}/>
        <title>
          {routerLocale === 'en'
            ? 'The Pinkfong Company, Inc.'
            : '더핑크퐁컴퍼니 주식회사'}
        </title>
        {OpengraphTag()}
        {RELEASE_ENV === 'production' && DefaultMetaTag()}
      </Head>
      {RELEASE_ENV === 'production' && <GoogleTagManagerNoScript />}
      <BasicLayout>
        {routerLocale === 'en' && checkNotFound(pathname) ? (<NotFound />) : (<Component {...pageProps}/>)}
      </BasicLayout>
      <FloatButton.BackTop />
    </ConfigProvider>);
}
const __Next_Translate__Page__190ed1d4024__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__190ed1d4024__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  