const RELEASE_ENV = process.env.NEXT_PUBLIC_RELEASE_ENV;
let API_BASE_URL = '';
let FRONT_API_BASE_URL = '';

switch (RELEASE_ENV) {
  case 'production':
    API_BASE_URL = 'https://www.thepinkfongcompany.com';
    FRONT_API_BASE_URL = 'https://www.thepinkfongcompany.com';
    break;
  case 'staging':
    API_BASE_URL = 'https://www-test.thepinkfongcompany.com';
    FRONT_API_BASE_URL = 'https://www-test.thepinkfongcompany.com';
    break;
  case 'development':
    API_BASE_URL = 'http://localhost:1337';
    FRONT_API_BASE_URL = 'http://localhost:3000';
    break;
  default:
    break;
}

export { API_BASE_URL, FRONT_API_BASE_URL, RELEASE_ENV };
