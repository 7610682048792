import Script from 'next/script';

function GTagScript() {
  return (
    <Script
      id="gtag-init"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'UA-47798183-6');
    `,
      }}
    />
  );
}

export default GTagScript;
