import Link from 'next/link';
import { Button, Menu, MenuProps } from 'antd';
import { useRouter } from 'next/router';
import Image from 'next/image';
import styles from './DefaultMenu.module.css';
import classNames from 'classnames/bind';
import logo from 'public/images/TPC_logo.svg';
import mobileMenuIcon from 'public/icons/hamburger.svg';
import { useEffect, useState } from 'react';
import LocaleSelector from './LocaleSelector';
import { CloseOutlined } from '@ant-design/icons';
import useTranslation from 'next-translate/useTranslation';
import useWindowSize from 'hooks/useWindowSize';

const cx = classNames.bind(styles);

interface IRouteMap {
  [key: string]: {
    title: string;
    query?: {
      page?: number;
    };
    display?: boolean;
  };
}

function DefaultMenu() {
  const { t: ct } = useTranslation('common');
  const { width } = useWindowSize();
  const isMobile = width ? width <= 780 : false;

  const router = useRouter();
  const { pathname, locale } = router;

  const [visible, setVisible] = useState(false);

  const MenuItem = (path: string) =>
    routeMap[path]?.display !== false && (
      <Link
        href={{
          pathname: path,
          ...(routeMap[path].query && { query: routeMap[path].query }),
        }}
      >
        <a title={routeMap[path].title}>
          <span>{routeMap[path].title}</span>
        </a>
      </Link>
    );

  const routeMap: IRouteMap = {
    '/company': { title: ct('about') },
    '/business': { title: ct('business') },
    '/news/pr': {
      title: ct('newsroom'),
      query: {
        page: 1,
      },
    },
    '/career': { title: ct('career') },
    ...(locale === 'ko' && {
      '/blog/interviews': {
        title: '블로그',
        query: {
          page: 1,
        },
      },
    }),
  };

  const handleVisibleChange = () => {
    setVisible((prev) => !prev);
  };

  const getSelectedKeys = () => {
    const linkPath = pathname.split('/');
    linkPath.shift();

    const [mainPath] = linkPath;

    return [`/${mainPath}`];
  };

  // 모달 열리면 배경 스크롤 막기
  useEffect(() => {
    if (!visible || !isMobile) return;
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = '';
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    };
  }, [visible, isMobile]);

  return (
    <div className={cx('wrapper')}>
      <Link href="/" passHref>
        <a className={cx('logo')} onClick={() => setVisible(false)}>
          <Image src={logo} width={100} height={32} alt="logo" />
        </a>
      </Link>
      <div className={cx('pc-menu')}>
        <div className={cx('locale-selector')}>
          <LocaleSelector />
        </div>
        <Menu
          mode="horizontal"
          selectedKeys={getSelectedKeys()}
          items={Object.keys(routeMap).map((key) => ({
            label: MenuItem(key),
            key: `/${key.split('/')[1]}`,
          }))}
        />
      </div>
      <div className={cx('mobile-menu')}>
        <Button type="text" onClick={handleVisibleChange}>
          {visible ? (
            <CloseOutlined
              style={{
                width: 20,
                height: 20,
                fontSize: 20,
                color: '#a0a0a0',
              }}
            />
          ) : (
            <Image
              src={mobileMenuIcon}
              width={20}
              height={20}
              alt="mobileMenuIcon"
            />
          )}
        </Button>
      </div>
      <div
        className={cx([
          'mobile-menu-modal',
          {
            hide: !visible,
          },
        ])}
      >
        <div className={cx('inner')}>
          <ul>
            {Object.keys(routeMap).map((key) => (
              <li key={key} onClick={() => setVisible(false)}>
                {MenuItem(key)}
              </li>
            ))}
          </ul>
          <div className={cx('locale-selector')}>
            <LocaleSelector onClick={() => setVisible(false)} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DefaultMenu;
