import styles from './FooterContent.module.css';
import classNames from 'classnames/bind';

import plusIcon from 'public/icons/plus.svg';
import facebookIcon from 'public/icons/facebook.svg';
import instagramIcon from 'public/icons/instagram.svg';
import linkedinIcon from 'public/icons/linkedin.svg';

import Image from 'next/image';
import { Button, Popover } from 'antd';
import LINK from 'constants/link';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { RELEASE_ENV } from 'utils/config';

const cx = classNames.bind(styles);

function FooterContent() {
  const { t: ct } = useTranslation('common');
  const router = useRouter();

  const { locale } = router;
  return (
    <div className={cx('wrapper')}>
      <div className={cx('link')}>
        <a href={LINK.facebook} target="_blank" rel="noreferrer">
          <Image src={facebookIcon} alt="facebook" width={35} height={35} />
        </a>
        <a href={LINK.linkedin} target="_blank" rel="noreferrer">
          <Image src={linkedinIcon} alt="linkedin" width={35} height={35} />
        </a>
        <a href={LINK.instagram} target="_blank" rel="noreferrer">
          <Image src={instagramIcon} alt="instagram" width={35} height={35} />
        </a>
        <Popover
          content={
            <ul className={cx('family-site')}>
              <li>
                <a href={LINK.pinkfong} target="_blank" rel="noreferrer">
                  {ct('pinkfong')}
                </a>
              </li>
              <li>
                <a href={LINK['babyshark-tv']} target="_blank" rel="noreferrer">
                  {ct('babyshark')}
                </a>
              </li>
              <li>
                <a
                  href={
                    LINK[
                      locale === 'en'
                        ? 'pinkfong-store-en'
                        : 'pinkfong-store-ko'
                    ]
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  {ct('pinkfong-store')}
                </a>
              </li>
            </ul>
          }
        >
          <Button
            type="default"
            shape="round"
            style={{
              width: '160px',
              height: '35px',
              padding: '4px 20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              border: 0,
              color: '#8c8c8c',
            }}
          >
            <span>{ct('family-site')}</span>
            <Image src={plusIcon} alt="plus" width={12} height={12} />
          </Button>
        </Popover>
      </div>
      <div className={cx('top')}>
        <ul>
          <li>
            <a
              href="https://bizhelp.pinkfong.com/hc/?utm_campaign=companyweb-footer&utm_source=companyweb"
              target="_blank"
              rel="noreferrer"
            >
              <strong>{ct('contact')}</strong>
            </a>
          </li>
          <li>
            <a
              href="https://pid.pinkfong.com/terms?type=privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              <strong>{ct('privacy-policy')}</strong>
            </a>
          </li>
          <li>
            <a
              href="https://pid.pinkfong.com/terms?type=terms-and-conditions"
              target="_blank"
              rel="noreferrer"
            >
              <strong>{ct('terms-of-use')}</strong>
            </a>
          </li>

          {locale === 'ko' && (
            <li>
              <a
                href={`https://${
                  RELEASE_ENV === 'production' ? 'www' : 'www-test'
                }.thepinkfongcompany.com/news/announcement/1`}
                target="_blank"
                rel="noreferrer"
              >
                <strong>윤리경영원칙</strong>
              </a>
            </li>
          )}
        </ul>
      </div>
      <div className={cx('bottom')}>
        {locale === 'ko' && (
          <>
            <div>더핑크퐁컴퍼니 | 대표 김민석</div>
            <div className={cx('company-address-container')}>
              <span>서울특별시 서초구 명달로 94, 5층 (06668)</span>
              <span className={cx('divider')}>|</span>
              <span>
                서울특별시 서초구 서초대로 38길 12, 마제스타시티 타워 2, 8층
                (06655)
              </span>
            </div>
          </>
        )}

        <div>{ct('copyright')}</div>
      </div>
    </div>
  );
}

export default FooterContent;
