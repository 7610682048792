import { Layout } from 'antd';
import DefaultMenu from './DefaultMenu';
import FooterContent from './FooterContent';

const { Header, Content, Footer } = Layout;

interface ILayoutProps {
  children: React.ReactNode;
}

export default function BasicLayout({ children }: ILayoutProps) {
  return (
    <Layout style={{ minHeight: '100vh', overflow: 'hidden' }}>
      <Header
        style={{
          position: 'fixed',
          width: '100%',
          left: 0,
          top: 0,
          right: 0,
          zIndex: 1000,
        }}
      >
        <DefaultMenu />
      </Header>
      <Content style={{ width: '100%', marginTop: '64px' }}>{children}</Content>
      <Footer style={{ textAlign: 'center' }}>
        <FooterContent />
      </Footer>
    </Layout>
  );
}
